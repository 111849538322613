<template>
    <div class="card-tail">
        <div>
            <el-form v-loading="$waiting.is('loading')">
                <div class="p-5">
                    <header class="text-header-blue text-xl font-semibold mb-8">Underskrifter</header>
                    <div v-if="signaturesData">
                        <SignatureInfo
                            v-if="signaturesData.signatures != null"
                            @saveData="saveData()"
                            @close="closeCard"
                            :title="signaturesData.signatures.headTitle"
                            :toggle-value="signaturesData.signatures.add"
                            @toggle-change="handleToggleChange"
                            :data="signaturesData.signatures"
                        />
                        <AuditorSignature
                            v-if="signaturesData.auditorSignatures != null"
                            @saveData="saveData()"
                            @close="closeCard"
                            :title="signaturesData.auditorSignatures.headTitle"
                            :toggle-value="signaturesData.auditorSignatures.add"
                            @toggle-change="handleToggleChange"
                            :data="signaturesData.auditorSignatures"
                        />
                        <!-- Modal för utkast -->
                        <!-- <ManagementReportPdfCard
                            ref="inspectManagementReportModal"
                            :client-info="clientInfo"
                            :visible="draftVisible"
                            @close="draftVisible = false"
                            :annual-report-data="annualReportData"
                        /> -->
                    </div>
                </div>

                <div class="p-5 flex justify-end w-full">
                    <el-button size="medium" type="primary" @click="goToPageBefore">Tillbaka</el-button>
                    <el-button size="medium" type="warning" @click="exportDraftPdf">Utkast Årsredovisning</el-button>
                    <el-button size="medium" class="button is-info" @click="showDraft">Granska</el-button>
                    <el-button size="medium" type="primary" @click="goToNextPage">Nästa</el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import Api from "../annualReport.api";
export default {
    props: {
        clientInfo: {
            type: Object,
        },
    },
    components: {
        SignatureInfo: () => import("./components/SignatureInfo.vue"),
        AuditorSignature: () => import("./components/AuditorSignature.vue"),
    },
    data() {
        return {
            annualReportId: undefined,
            draftVisible: false,
            signaturesData: {},
            oldInputText: "",
        };
    },
    async created() {
        this.$waiting.start("loading");
        this.annualReportId = this.$route.params.annualReportId;
        this.signaturesData = await Api.getSignatureDetails(this.annualReportId);
        this.$waiting.end("loading");
    },
    methods: {
        async goToNextPage() {
            await this.$router.push(`/annualReport/annualGeneralMeetingMinutes/${this.annualReportId}`);
        },
        async goToPageBefore() {
            await this.$router.push(`/annualReport/notes/${this.annualReportId}`);
        },
        showDraft() {
            // this.draftVisible = true;
            // this.$nextTick(() => {
            //     this.$refs.inspectManagementReportModal.$el.focus();
            // });
        },
        exportDraftPdf() {
            console.log("Exporting draft pdf");
        },
        async handleToggleChange(eventData) {
            if (eventData.source === 1) {
                this.signaturesData.signatures.add = eventData.value;
            }
            if (eventData.source === 2) {
                this.signaturesData.auditorSignatures.add = eventData.value;
            }
            await Api.updateSignatureDetails(this.annualReportId, this.signaturesData);
        },
        async saveData() {
            await Api.updateSignatureDetails(this.annualReportId, this.signaturesData);
        },
        async closeCard(value = {}) {
            console.log("closeCard", value);
            this.signaturesData = await Api.getSignatureDetails(this.annualReportId);
        },
    },
};
</script>

<style>
.custom-textarea .el-textarea__inner:disabled {
    color: #444a55;
    border-color: #aaaaaa;
    background-color: #eeeeee;
    font-size: 14px;
}
.custom-textarea {
    font-size: 14px;
}
.round-button,
.round-button:active,
.round-button:focus {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #415770;
    font-size: x-large;
}
.round-button:hover {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #6e85a0;
    font-size: x-large;
}
.save-button {
    background-color: #48c78e;
    border-color: #48c78e;
    color: #ffffff;
}
.save-button:hover {
    background-color: #5bd6a3;
    color: #ffffff;
}
.tooltip-content {
    width: 500px;
    padding: 5px;
    border: none;
}
.management-report-card-box {
    margin-top: 40px;
    max-width: fit-content;
    min-width: 800px;
    overflow: auto;
}
</style>
